@media screen and (max-width: 991px){
  .sidebar-collapse .navbar-collapse:before {
    background: $info-color;
    background: linear-gradient(#4A0000, #690000 80%);
  }
  .navbar-nav .dropdown-menu {
    position: static !important;
  }
}

@media screen and (max-width: 768px){
  .nav-tabs {
    padding-left: 10px;
    padding-right: 10px;
  }
}
